import { Button, Col, Form, InputNumber, Modal, Row, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import partServices from "../../services/part.service";
import CurrencyFormat from "../Format/CurrencyFormat";
import { useTranslation } from "react-i18next";
import {
  FileSearchOutlined,
  FileTextOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import "./miniReport.module.scss";
import FadeIn from "../Shape/FadeIn";
import LoadingComponent from "../Loading/LoadingComponent";
import { projectActions } from "app/redux/slides/project.slide";
import { useDispatch } from "react-redux";

interface DefaultProps {
  partId?: number;
  projectId?: number | string;
  onAdapted?: any;
  onCancel?: any;
  readonly?: boolean;
}

function MiniReport(props: DefaultProps) {
  const { partId, onAdapted, onCancel, readonly = false } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [miniReportData, setMiniReportData] = useState<any>();
  const [historyMiniReportData, setHistoryMiniReportData] = useState<any>();
  const [modelAnalysis, setModelAnalysis] = useState<any>();
  const [report, setReport] = useState<any>();
  const [modelDimensions, setModelDimensions] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm();
  const validateRules = [{ required: true, message: t("required") }];
  const inputRefs: any = {};
  const [unitPrice, setUnitPrice] = useState(0);
  const [adaptedUnitPrice, setAdaptedUnitPrice] = useState(0);
  const [partDiscount, setPartDiscount] = useState(0);
  const [isOpenMiniReport, setIsOpenMiniReport] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const rs = await partServices.getMiniReport(partId);
      setHistoryMiniReportData(rs);
      setResults(rs);
    } catch (error) {}
    setLoading(false);
  };

  const setResults = (rs: any) => {
    setMiniReportData(rs);
    setPartDiscount(rs.discount);
    setUnitPrice(rs.unitPrice);
    setAdaptedUnitPrice(rs.adaptedUnitPrice);
    setModelAnalysis(rs.modelAnalysisData);
    setReport(rs.report);
    setModelDimensions(JSON.parse(rs.modelAnalysisData.modelDimensions));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!isEdit) {
      form.resetFields();
    }
  }, [isEdit]);

  const formatData = (formData: any) => {
    let format: any = {};
    for (let type in formData) {
      format[type] = [];
      for (let machineId in formData[type]) {
        let _data = formData[type][machineId];
        _data.machineId = machineId;
        format[type].push(_data);
      }
    }

    return format;
  };

  const PreviewAdaptedPartUnitPrice = async () => {
    setLoading(true);
    try {
      const formData = await form.getFieldsValue();
      let formatedData = formatData(formData);
      formatedData.discount = partDiscount;
      const rs = await partServices.postRecalcutatePart(partId, formatedData);
      setHistoryMiniReportData(miniReportData);
      setResults(rs);
    } catch (error) {}
    setLoading(false);
  };

  const updateTimeAndCostAdapt = async () => {
    setLoading(true);
    try {
      const formData = form.getFieldsValue();
      let formatedData = formatData(formData);
      formatedData.discount = partDiscount;
      const rs = await partServices.postUpdatePartTimeAndCostsAdapt(
        partId,
        formatedData
      );
      setAdaptedUnitPrice(rs.adaptedUnitPrice);

      dispatch(projectActions.setPart(rs));

      getData();
      setIsEdit(false);
      if (onAdapted) onAdapted(rs);
    } catch (error) {}
    setLoading(false);
  };

  const handleAddInputRef = (fieldName: any) => (ref: any) => {
    inputRefs[fieldName] = ref;
  };

  const resetAdaptTimeValue = (
    inputTimeName: any,
    inputCostName: string,
    value: any
  ) => {
    form.setFieldValue(inputTimeName, value);
  };

  const resetAdaptCostValue = (
    inputTimeName: string,
    inputCostName: string,
    value: any
  ) => {
    form.setFieldValue(inputCostName, value);
    handleCostsChange(inputCostName, inputTimeName, value);
  };

  const handleCostsChange = (
    inputCostName: any,
    inputTimeName: string,
    oldCosts: any
  ) => {
    const newCosts = form.getFieldValue(inputCostName).toString();
    const nameInputInstance = form.getFieldInstance(inputTimeName);
    const parentDiv = inputRefs[inputTimeName].closest(".ant-input-number");
    const reloadButton = inputRefs[inputTimeName]
      .closest(".ant-space-compact")
      .querySelector("button");

    if (newCosts != oldCosts.toString()) {
      nameInputInstance.disabled = true;
      parentDiv.classList.add("ant-input-number-disabled");
      reloadButton.setAttribute("disabled", true);
    } else {
      nameInputInstance.disabled = false;
      parentDiv.classList.remove("ant-input-number-disabled");
      reloadButton.removeAttribute("disabled");
    }
  };

  if (loading && !miniReportData) {
    return (
      <div className="box-loading" style={{padding: "0.857rem", margin: 0}}>
        <Spin />
      </div>
    );
  }

  const handleCancelEdit = () => {
    if (historyMiniReportData) {
      setReport(null);
      setResults(historyMiniReportData);
      setHistoryMiniReportData(null);
    }
    setIsEdit(false);
  };

  const handleChangeDiscount = (value: any) => {
    if (value >= 0 && value <= 100) {
      setPartDiscount(value);
    }
  };

  return (
    <>
      <Button className="" onClick={setIsOpenMiniReport.bind(null, true)}>
        <FileTextOutlined /> {t("part.miniReport")}
      </Button>
      <Modal
        open={isOpenMiniReport}
        title={t("part.miniReport")}
        width={"65vw"}
        className="modal-body-scroll"
        onCancel={setIsOpenMiniReport.bind(null, false)}
        footer={
          !readonly ? (
            <Col xs={12} sm={24}>
              <div
                className="action-footer gap-2"
                style={{ display: "flex", justifyContent: "end" }}
              >
                {!isEdit && (
                  <>
                    <Button
                      onClick={setIsEdit.bind(null, true)}
                      type="primary"
                      icon={<SettingOutlined />}
                    >
                      {t("edit")}
                    </Button>
                    <Button
                      onClick={setIsOpenMiniReport.bind(null, false)}
                      type="default"
                    >
                      {t("cancel")}
                    </Button>
                  </>
                )}
                {isEdit && (
                  <>
                    <Button type="primary" onClick={updateTimeAndCostAdapt}>
                      {t("save")}
                    </Button>
                    <Button
                      icon={<FileSearchOutlined />}
                      onClick={PreviewAdaptedPartUnitPrice}
                    >
                      Preview
                    </Button>
                    <Button onClick={handleCancelEdit}>{t("cancel")}</Button>
                  </>
                )}
              </div>
            </Col>
          ) : null
        }
        destroyOnClose
      >
        <div className="mini-report-wrapper" style={{ position: "relative" }}>
          <LoadingComponent loading={loading} />
          <Col span={24} className="modal-fixed">
            <div className="mini-group">
              <div className="sub-l">{t("material")}</div>
              <div className="row">
                <div className="col col-lg-4">
                  <label className="fw-bold">
                    {t("miniReport.blankDimension")}:
                  </label>
                </div>
                <div className="col col-lg-8 pb-2">
                  <Space direction="vertical" size={0.2}>
                    {modelDimensions.type && (
                      <label>
                        <span className="text-label">
                          {t("miniReport.rawPartType")}:
                        </span>{" "}
                        {modelDimensions.type}
                      </label>
                    )}
                    {/* <label>Länge:</label> */}
                    {modelAnalysis.modelDimensionLength && (
                      <label>
                        <span className="text-label">{t("length")}:</span>{" "}
                        {modelAnalysis.modelDimensionLength} mm
                      </label>
                    )}
                    {/* <label>Breite:</label> */}
                    {modelAnalysis.materialShapeDimensionsWidth && (
                      <label>
                        <span className="text-label">{t("width")}:</span>{" "}
                        {modelAnalysis.materialShapeDimensionsWidth} mm
                      </label>
                    )}
                    {modelAnalysis.materialShapeDimensionsHeight && (
                      <label>
                        <span className="text-label">{t("height")}:</span>{" "}
                        {modelAnalysis.materialShapeDimensionsHeight} mm
                      </label>
                    )}
                    {modelAnalysis.materialShapeDimensionsOuterDiameter && (
                      <label>
                        <span className="text-label">
                          {t("miniReport.outerDiameter")}:
                        </span>{" "}
                        {modelAnalysis.materialShapeDimensionsOuterDiameter} mm
                      </label>
                    )}
                    {modelAnalysis.materialShapeDimensionsInnerDiameter && (
                      <label>
                        <span className="text-label">
                          {t("miniReport.innerDiameter")}:
                        </span>{" "}
                        {modelAnalysis.materialShapeDimensionsInnerDiameter} mm
                      </label>
                    )}
                  </Space>
                </div>
                <div className="col col-lg-4">
                  <label className="fw-bold">{t("price.unitPrice")}:</label>
                </div>
                <div className="col col-lg-8">
                  <span className="fw-bold">
                    {report?.materialCost && (
                      <CurrencyFormat value={report?.materialCost} />
                    )}
                  </span>
                </div>
              </div>
            </div>
            {isEdit && (
              <FadeIn>
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  // onFinish={onFinish}
                  autoComplete="off"
                >
                  <div className="mini-group">
                    <div className="sub-l">{t("miniReport.setup")}</div>
                    <div className="row border-bottom fw-bold pb-2">
                      <div className="col-4">{t("machines")}</div>
                      <div className="col-2">{t("time")} (in Min.)</div>
                      <div className="col-4">Adapt {t("time")} (in Min.)</div>
                      <div className="col-2">{t("price")} (in €)</div>
                      {/* <div className="col-2">Adapt {t("price")} (in €)</div> */}
                    </div>
                    {report?.stages?.setup.map((machine: any) => (
                      <div
                        key={`machine-${machine.machine}`}
                        className="row border-bottom"
                      >
                        <div className="col-4 col-data">
                          {t(`machines.${machine.machine}`)}
                        </div>
                        <div className="col-2 col-data">
                          {machine.tr.toFixed(2)}
                        </div>
                        <div className="col-4">
                          <Space.Compact>
                            <Form.Item
                              rules={validateRules}
                              className="mb-2 mt-2"
                              initialValue={
                                machine.adaptTr
                                  ? machine.adaptTr
                                  : machine.tr.toFixed(2)
                              }
                              name={["setup", machine.machine, "adaptTr"]}
                            >
                              <InputNumber
                                min={0}
                                ref={handleAddInputRef(
                                  `setup[${machine.machine}]adaptTr`
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              rules={validateRules}
                              className="mb-2 mt-2"
                            >
                              <Button
                                className="btn-reload"
                                size="small"
                                icon={
                                  <ReloadOutlined style={{ fontSize: 14 }} />
                                }
                                onClick={() =>
                                  resetAdaptTimeValue(
                                    ["setup", machine.machine, "adaptTr"],
                                    `setup[${machine.machine}]adaptCosts`,
                                    machine.tr.toFixed(2)
                                  )
                                }
                              ></Button>
                            </Form.Item>
                          </Space.Compact>
                        </div>
                        <div className="col-2 col-data">
                          <CurrencyFormat
                            hideCurrency={false}
                            value={machine.costs}
                          ></CurrencyFormat>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mini-group">
                    <div className="sub-l">
                      {t("miniReport.programmingTime")}
                    </div>
                    <div className="row border-bottom fw-bold pb-2">
                      <div className="col-4">{t("machines")}</div>
                      <div className="col-2">{t("time")} (in Min.)</div>
                      <div className="col-4">Adapt {t("time")} (in Min.)</div>
                      <div className="col-2">{t("price")} (in €)</div>
                      {/* <div className="col-2">Adapt {t("price")} (in €)</div> */}
                    </div>
                    {report?.stages.programming.map(
                      (programming: any, index: number) => (
                        <div
                          key={`programming-${index}`}
                          className="row border-bottom"
                        >
                          <div className="col-4 col-data">
                            {t(`machines.${programming.machine}`)}
                          </div>
                          <div className="col-2 col-data">
                            {programming.time.toFixed(2)}
                          </div>
                          <div className="col-4">
                            <Space.Compact>
                              <Form.Item
                                rules={validateRules}
                                className="mb-2 mt-2"
                                initialValue={
                                  programming.adaptTime
                                    ? programming.adaptTime
                                    : programming.time.toFixed(2)
                                }
                                name={[
                                  "programming",
                                  programming.machine,
                                  "adaptTime",
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  ref={handleAddInputRef(
                                    `programming[${programming.machine}]adaptTime`
                                  )}
                                />
                              </Form.Item>
                              <Form.Item
                                rules={validateRules}
                                className="mb-2 mt-2"
                              >
                                <Button
                                  className="btn-reload"
                                  size="small"
                                  icon={
                                    <ReloadOutlined style={{ fontSize: 14 }} />
                                  }
                                  onClick={() =>
                                    resetAdaptTimeValue(
                                      [
                                        "programming",
                                        programming.machine,
                                        "adaptTime",
                                      ],
                                      `programming[${programming.machine}]adaptCosts`,
                                      programming.time.toFixed(2)
                                    )
                                  }
                                ></Button>
                              </Form.Item>
                            </Space.Compact>
                          </div>
                          <div className="col-2 col-data">
                            <CurrencyFormat
                              hideCurrency={false}
                              value={programming.costs}
                            ></CurrencyFormat>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="mini-group">
                    <div className="sub-l">
                      {t("miniReport.processingTime")}
                    </div>
                    <div className="row border-bottom fw-bold pb-2">
                      <div className="col-4">{t("machines")}</div>
                      <div className="col-2">{t("time")} (in Min.)</div>
                      <div className="col-4">Adapt {t("time")} (in Min.)</div>
                      <div className="col-2">{t("price")} (in €)</div>
                      {/* <div className="col-2">Adapt {t("price")} (in €)</div> */}
                    </div>
                    {report?.machines.map((machine: any) => (
                      <div
                        key={`machine-${machine.id}`}
                        className="row border-bottom"
                      >
                        <div className="col-4 col-data">
                          {machine.localizedName}
                        </div>
                        <div className="col-2 col-data">
                          {machine.te.toFixed(2)}
                        </div>
                        <div className="col-4 col-data">
                          <Space.Compact>
                            <Form.Item
                              rules={validateRules}
                              className="mb-2 mt-2"
                              initialValue={
                                machine.adaptTe
                                  ? machine.adaptTe
                                  : machine.te.toFixed(2)
                              }
                              name={["process", machine.id, "adaptTe"]}
                            >
                              <InputNumber
                                min={0}
                                ref={handleAddInputRef(
                                  `process[${machine.machine}]adaptTe`
                                )}
                              />
                            </Form.Item>
                            <Form.Item
                              rules={validateRules}
                              className="mb-2 mt-2"
                            >
                              <Button
                                className="btn-reload"
                                size="small"
                                icon={
                                  <ReloadOutlined style={{ fontSize: 14 }} />
                                }
                                onClick={() =>
                                  resetAdaptTimeValue(
                                    ["process", machine.id, "adaptTe"],
                                    `process[${machine.id}]adaptCosts`,
                                    machine.te.toFixed(2)
                                  )
                                }
                              ></Button>
                            </Form.Item>
                          </Space.Compact>
                        </div>
                        <div className="col-2 col-data">
                          <CurrencyFormat
                            hideCurrency={false}
                            value={machine.costs}
                          ></CurrencyFormat>
                        </div>
                      </div>
                    ))}
                  </div>
                </Form>
              </FadeIn>
            )}
            {!isEdit && (
              <FadeIn>
                <div className="mini-group">
                  <div className="sub-l">{t("miniReport.setup")}</div>
                  <div className="row border-bottom fw-bold pb-2">
                    <div className="col-4">{t("machines")}</div>
                    <div className="col-4">{t("time")} (in Min.)</div>
                    <div className="col-4">{t("price")} (in €)</div>
                  </div>
                  {report?.stages?.setup.map((machine: any) => (
                    <div
                      key={`machine-${machine.machine}`}
                      className="row border-bottom"
                    >
                      <div className="col-4 col-data">
                        {t(`machines.${machine.machine}`)}
                      </div>
                      <div className="col-4 col-data">
                        {machine.adaptTr &&
                          machine.adaptTr.toFixed(2) !=
                            machine.tr.toFixed(2) && (
                            <span className="text-line-throught me-2">
                              {machine.tr.toFixed(2)}
                            </span>
                          )}
                        {machine.adaptTr &&
                        machine.adaptTr.toFixed(2) != machine.tr.toFixed(2)
                          ? machine.adaptTr.toFixed(2)
                          : machine.tr.toFixed(2)}
                      </div>
                      <div className="col-4 col-data">
                        {machine.adaptCosts &&
                          machine.adaptCosts != machine.costs && (
                            <span className="text-line-throught me-2">
                              <CurrencyFormat
                                hideCurrency={true}
                                value={machine.costs}
                              ></CurrencyFormat>
                            </span>
                          )}
                        <CurrencyFormat
                          hideCurrency={true}
                          value={
                            machine.adaptCosts &&
                            machine.adaptCosts != machine.costs
                              ? machine.adaptCosts
                              : machine.costs
                          }
                        ></CurrencyFormat>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mini-group">
                  <div className="sub-l">{t("miniReport.programmingTime")}</div>
                  <div className="row border-bottom fw-bold pb-2">
                    <div className="col-4">{t("machines")}</div>
                    <div className="col-4">{t("time")} (in Min.)</div>
                    <div className="col-4">{t("price")} (in €)</div>
                  </div>
                  {report?.stages.programming.map(
                    (programming: any, index: number) => (
                      <div
                        key={`programming-${index}`}
                        className="row border-bottom"
                      >
                        <div className="col-4 col-data">
                          {t(`machines.${programming.machine}`)}
                        </div>
                        <div className="col-4 col-data">
                          {programming.adaptTime &&
                            programming.adaptTime.toFixed(2) !=
                              programming.time.toFixed(2) && (
                              <span className="text-line-throught me-2">
                                {programming.time.toFixed(2)}
                              </span>
                            )}
                          {programming.adaptTime &&
                          programming.adaptTime.toFixed(2) !=
                            programming.time.toFixed(2)
                            ? programming.adaptTime.toFixed(2)
                            : programming.time.toFixed(2)}
                        </div>
                        <div className="col-4 col-data">
                          {programming.adaptCosts &&
                            programming.adaptCosts != programming.costs && (
                              <span className="text-line-throught me-2">
                                <CurrencyFormat
                                  hideCurrency={true}
                                  value={programming.costs}
                                ></CurrencyFormat>
                              </span>
                            )}
                          <CurrencyFormat
                            hideCurrency={true}
                            value={
                              programming.adaptCosts &&
                              programming.adaptCosts != programming.costs
                                ? programming.adaptCosts
                                : programming.costs
                            }
                          ></CurrencyFormat>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="mini-group">
                  <div className="sub-l">{t("miniReport.processingTime")}</div>
                  <div className="row border-bottom fw-bold pb-2">
                    <div className="col-4">{t("machines")}</div>
                    <div className="col-4">{t("time")} (in Min.)</div>
                    <div className="col-4">{t("price")} (in €)</div>
                  </div>
                  {report?.machines.map((machine: any) => (
                    <div
                      key={`machine-${machine.id}`}
                      className="row border-bottom"
                    >
                      <div className="col-4 col-data">
                        {t(`machines.${machine.id}`)}
                      </div>
                      <div className="col-4 col-data">
                        {machine.adaptTe &&
                          machine.adaptTe.toFixed(2) !=
                            machine.te.toFixed(2) && (
                            <span className="text-line-throught me-2">
                              {machine.te.toFixed(2)}
                            </span>
                          )}
                        {machine.adaptTe &&
                        machine.adaptTe.toFixed(2) != machine.te.toFixed(2)
                          ? machine.adaptTe.toFixed(2)
                          : machine.te.toFixed(2)}
                      </div>
                      <div className="col-4 col-data">
                        {machine.adaptCosts &&
                          machine.adaptCosts != machine.costs && (
                            <span className="text-line-throught me-2">
                              <CurrencyFormat
                                hideCurrency={true}
                                value={machine.costs}
                              ></CurrencyFormat>
                            </span>
                          )}
                        <CurrencyFormat
                          hideCurrency={true}
                          value={
                            machine.adaptCosts &&
                            machine.adaptCosts != machine.costs
                              ? machine.adaptCosts
                              : machine.costs
                          }
                        ></CurrencyFormat>
                      </div>
                    </div>
                  ))}
                  {report?.inBetweenTemperingCost > 0 && (
                    <div className="row border-bottom">
                      <div className="col-4 col-data">
                        {t("miniReport.inBetweenTemperingCost")}
                      </div>
                      <div className="col-4 col-data">--</div>
                      <div className="col-4 col-data">
                        <CurrencyFormat
                          value={report.inBetweenTemperingCost}
                        ></CurrencyFormat>
                      </div>
                    </div>
                  )}
                </div>
              </FadeIn>
            )}
            <div className="mini-group">
              <div className="sub-l">{t("menu.surfaceTreatments")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-4">Name</div>
                <div className="col-8">{t("cost")}</div>
              </div>
              {miniReportData.partSurfaceTreatmentList?.map(
                (surfaceTreatment: any, index: number) => (
                  <div
                    key={`surfaceTreatment-${index}`}
                    className="row border-bottom py-2"
                  >
                    <div className="col-4">{surfaceTreatment.method}</div>
                    <div className="col-8">
                      <CurrencyFormat
                        value={surfaceTreatment.surfaceTreatmentCost}
                      />
                    </div>
                  </div>
                )
              )}
              {!miniReportData.partSurfaceTreatmentList.length && (
                <div className="row border-bottom">
                  <div className="col-4 col-data">No data</div>
                  <div className="col-8 col-data">No data</div>
                </div>
              )}
            </div>
          </Col>
          <Col span={24}>
            <div className="row mt-4">
              <Row>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  {isEdit && (
                    <Form.Item label={t("discount")}>
                      <InputNumber
                        onChange={handleChangeDiscount}
                        defaultValue={partDiscount}
                        min={0}
                        max={100}
                        addonAfter={"%"}
                        style={{ width: 120 }}
                      />
                    </Form.Item>
                  )}
                  {!isEdit && (
                    <span>
                      <b>{t("discount")}: </b> {miniReportData.discount}%
                    </span>
                  )}
                </Col>

                <Col
                  xs={12}
                  sm={24}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <h5>
                    <b>{t("price.unitPrice")}: </b>
                    {adaptedUnitPrice != null &&
                      adaptedUnitPrice?.toFixed(2) != unitPrice?.toFixed(2) && (
                        <small className="text-line-throught me-2">
                          <CurrencyFormat value={unitPrice} />
                        </small>
                      )}
                    <span>
                      <CurrencyFormat
                        value={
                          adaptedUnitPrice != null &&
                          adaptedUnitPrice?.toFixed(2) != unitPrice?.toFixed(2)
                            ? adaptedUnitPrice
                            : unitPrice
                        }
                      ></CurrencyFormat>
                    </span>
                  </h5>
                </Col>
              </Row>
            </div>
          </Col>
        </div>
      </Modal>
    </>
  );
}

export default MiniReport;

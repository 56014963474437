import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { authSelector } from "app/redux/slides/auth.slide";
import userServices from "app/services/user.service";
import { profileSelector } from "app/redux/slides/user.slide";
import { Dropdown, MenuProps } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { configSelector } from "app/redux/slides/config.slide";
import ENIcon from "../SVGs/ENIcon";
import DEIcon from "../SVGs/DEIcon";
import ITIcon from "../SVGs/ITIcon";
import CZIcon from "../SVGs/CZIcon";

const locales: any = {
  en: {
    key: "en",
    label: "English",
    icon: <ENIcon/>
  },
  de: {
    key: "de",
    label: "German",
    icon: <DEIcon/>
  },
  it: {
    key: "it",
    label: "Italian",
    icon: <ITIcon/>
  },
  cs: {
    key: "cs",
    label: "Czech",
    icon: <CZIcon />
  },
};

const LocaleButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const user = useSelector(profileSelector);
  const lang = useSelector(langSelector);
  const config = useSelector(configSelector);
  // const locales = config.locales || [];
  const [items, setItems] = useState<any>(
    config.locales?.map((l: any) => locales[l]) || []
  );

  const langChange = async (key: string) => {
    moment.locale(key);
    await i18next.changeLanguage(key);
    dispatch(localeActions.setLang(key));
    if (auth.isLogined) userServices.changeLanguage(key.toUpperCase());
  };
  useEffect(() => {
    setItems(config.locales?.map((l: any) => locales[l]));
  }, [config]);
  useEffect(() => {
    if (auth.isLogined && user) {
      if (!user.locale) {
        userServices.changeLanguage(lang.toUpperCase());
      } else {
        langChange(user.locale.toLowerCase());
      }
    }
  }, [user]);
  const onMenuCLick = ({ key }: any) => {
    langChange(key);
  };
  return (
    <div className="locale">
      <Dropdown menu={{ items, onClick: onMenuCLick, className: "dropdown-locales" }}>
        <a onClick={(e) => e.preventDefault()}>
          <button className="btn">
            {lang === "de" && (
              <object type="image/svg+xml" data="/images/de.svg"></object>
            )}
            {lang === "en" && (
              <object type="image/svg+xml" data="/images/en.svg"></object>
            )}
            {lang === "it" && (
              <object type="image/svg+xml" data="/images/it.svg"></object>
            )}
            {lang === "cs" && (
              <object type="image/svg+xml" data="/images/cz.svg"></object>
            )}
          </button>
        </a>
      </Dropdown>
    </div>
  );
};

export default LocaleButton;

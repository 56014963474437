import {
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import LoadingComponent from "../Loading/LoadingComponent";
import partServices from "../../services/part.service";
import { CostReport } from "app/models/CostReport";
import CurrencyFormat from "../Format/CurrencyFormat";

interface DefaultProps {
  partId?: number;
  projectId?: number | string;
  onAdapted?: any;
  onCancel?: any;
  readonly?: boolean;
}

function CostReportModal(props: DefaultProps) {
  const { partId, onAdapted, onCancel, readonly = false } = props;
  const { t } = useTranslation();
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [costReport, setCostReport] = useState<CostReport>();

  const getData = async () => {
    setLoading(true);
    try {
      const rs = await partServices.getCostReport(partId);
      setCostReport(rs);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <LoadingComponent loading={loading} />
      <Button onClick={setIsOpenReport.bind(null, true)}>
        <FileTextOutlined /> {t("costReport")}
      </Button>
      <Modal
        open={isOpenReport}
        title={t("costReport")}
        width={"65vw"}
        className="modal-body-scroll"
        onCancel={setIsOpenReport.bind(null, false)}
        footer={
          <Row>
            <Col span={12} style={{textAlign: "start"}}>
                <b>{t("price.unitPrice")}</b>: <CurrencyFormat value={costReport?.unitPrice} />
            </Col>
            <Col span={12}>
              <Button
                onClick={setIsOpenReport.bind(null, false)}
                type="default"
              >
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        }
        destroyOnClose
      >
        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.rawMaterial")}</h6>
          </Divider>
          <div className="mini-group">
            <div className="row">
              <div className="col col-lg-12">
                <label className="fw-bold">{t("costReport.dimensions")}:</label>
                <span>
                  &nbsp; {costReport?.length?.toFixed(2)} x{" "}
                  {costReport?.height?.toFixed(2)} x{" "}
                  {costReport?.thickness?.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col col-lg-6">
                <label className="fw-bold">{t("costReport.weight")}:</label>
                <span>&nbsp; {costReport?.weight?.toFixed(2)}</span>
              </div>
              <div className="col col-lg-6 pb-2">
                <label className="fw-bold">{t("costReport.costs")}:</label>
                <span>
                  &nbsp; <CurrencyFormat value={costReport?.materialCosts} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.setup")}</h6>
          </Divider>
          {costReport?.cuttingSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.laser")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.cuttingSetupTime?.toFixed(2)}
                </div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.cuttingSetupCosts} />
                </div>
              </div>
            </div>
          )}

          {costReport?.foldingSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.bending")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.foldingSetupTime?.toFixed(2)}
                </div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.foldingSetupCosts} />
                </div>
              </div>
            </div>
          )}

          {costReport?.deburringSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.deburring")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.deburringSetupTime?.toFixed(2)}
                </div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.deburringSetupCosts} />
                </div>
              </div>
            </div>
          )}

          {costReport?.threadCuttingSetupTime && (
            <div className="mini-group">
              <div className="sub-l">Threads Cutting</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.threadCuttingSetupTime?.toFixed(2)}
                </div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.threadCuttingSetupCosts} />
                </div>
              </div>
            </div>
          )}

          {costReport?.countersinkSetupTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.countersinks")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.countersinkSetupTime?.toFixed(2)}
                </div>
                {costReport?.countersinkSetupCosts?.toFixed(2) > 0.0 && (
                  <div className="col-6">
                    <CurrencyFormat value={costReport?.countersinkSetupCosts} />
                  </div>
                )}
                {costReport?.countersinkSetupCosts?.toFixed(2) == 0.0 && (
                  <div className="col-6">
                    <CurrencyFormat
                      value={costReport?.countersinkSetupCosts}
                      maximumFractionDigits={6}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.programming")}</h6>
          </Divider>
          {costReport?.cuttingProgrammingTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.laser")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.cuttingProgrammingTime?.toFixed(2)}
                </div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.cuttingProgrammingCosts} />
                </div>
              </div>
            </div>
          )}

          {costReport?.foldingProgrammingTime && (
            <div className="mini-group">
              <div className="sub-l">{t("costReport.bending")}</div>
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">
                  {costReport?.foldingProgrammingTime?.toFixed(2)}
                </div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.foldingProgrammingCosts} />
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <Divider orientation="left" orientationMargin={0}>
            <h6>{t("costReport.processing")}</h6>
          </Divider>
          <div className="mini-group">
            <div className="row border-bottom fw-bold pb-2">
              <div className="col-6">{t("timeInSec")}</div>
              <div className="col-6">{t("priceInEur")}</div>
            </div>
            {costReport?.cuttingCosts > 0 && (
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">{t("costReport.laser")}</div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.cuttingCosts} />
                </div>
              </div>
            )}
            {costReport?.foldingCosts > 0 && (
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">{t("costReport.bending")}</div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.foldingCosts} />
                </div>
              </div>
            )}
            {costReport?.deburring > 0 && (
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">{t("costReport.deburring")}</div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.deburring} />
                </div>
              </div>
            )}
            {costReport?.threadCosts > 0 && (
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">Threads Cutting</div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.threadCosts} />
                </div>
              </div>
            )}
            {costReport?.countersinkCosts > 0 && (
              <div className="row border-bottom pt-2 pb-2">
                <div className="col-6">{t("costReport.countersinks")}</div>
                <div className="col-6">
                  <CurrencyFormat value={costReport?.countersinkCosts} />
                </div>
              </div>
            )}
          </div>
        </div>

        {costReport?.surfaceTreatment && (
          <div>
            <Divider orientation="left" orientationMargin={0}>
              <h6>{t("costReport.surfaceTreatment")}</h6>
            </Divider>
            <div className="mini-group">
              <div className="row border-bottom fw-bold pb-2">
                <div className="col-6">{t("timeInSec")}</div>
                <div className="col-6">{t("priceInEur")}</div>
              </div>
              {costReport?.surfaceTreatment.map((item: any) => (
                <div key={item.method} className="row border-bottom pt-2 pb-2">
                  <div className="col-6">{item.method}</div>
                  <div className="col-6">
                    <CurrencyFormat value={item?.surfaceTreatmentCost} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}

export default CostReportModal;

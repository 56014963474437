import { Button } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MillingAndTurningMachineSettings from "./MillingAndTurningMachineSettings";
import FadeIn from "app/components/Shape/FadeIn";
import SheetMetalMachineSettings from "./SheetMetalMachineSettings";
export function MachineSettingsPage() {
  const { t } = useTranslation();
  const [keySelected, setKeySelected] = useState("default");

  const menus = [
    {
      key: "default",
      label: t("machineSettings.millingAndTurning"),
    },
    {
      key: "sheetmetal",
      label: t("machineSettings.sheetmetal"),
    },
  ];

  const onMenuSelected = (m: any) => {
    setKeySelected(m.key);
  };

  return (
    <>
      <div className="b-settings">
        <div className="row">
          <div className="col col-12 col-lg d-lg-flex flex-lg-column gap-3 b-menus">
            {menus.map((m) => (
              <Button
                key={`m-${m.key}`}
                type={m.key === keySelected ? "primary" : "default"}
                shape="round"
                onClick={onMenuSelected.bind(null, m)}
              >
                {m.label}
              </Button>
            ))}
          </div>
          <div className="col col-12 col-lg-8 col-xl-10">
            {keySelected === "default" && (
              <FadeIn>
                <MillingAndTurningMachineSettings />
              </FadeIn>
            )}

            {keySelected === "sheetmetal" && (
              <FadeIn>
                <SheetMetalMachineSettings />
              </FadeIn>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

import { Collapse, notification } from "antd";
import SheetMetalMachineForm from "app/components/Machine/SheetMetalMachineForm";
import { API } from "app/constants/api.constant";
import { MachineInput, SheetMetalMachineInput } from "app/models/Machine";
import { loadingActions } from "app/redux/slides/loading.slide";
import machineSettingServices from "app/services/machineSetting.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
const { Panel } = Collapse;

interface DefaultProps {}

const SheetMetalMachineSettings = (props: DefaultProps) => {
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [machineSettings, setMachineSettings] =
    useState<SheetMetalMachineInput>();

  const getMachines = async () => {
    try {
      dispatch(loadingActions.show(true));
      const rs: SheetMetalMachineInput =
        await machineSettingServices.sheetMetalMachineSettings();
      setMachineSettings(rs);
    } catch (error) {}
    dispatch(loadingActions.show(false));
  };

  useEffect(() => {
    getMachines();
  }, []);

  const cuttingMachineSetting: MachineInput[] = [
    {
      name: "possibleCuttingWidth",
      unit: "mm",
      min: 0,
      type: "number",
    },
    {
      name: "possibleCuttingHeight",
      unit: "mm",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingMachineHourRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingMachineProgrammingHourRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingMachineSetupTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingMachineProgrammingTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingAccelerationFactor",
      unit: "%",
      min: 0,
      max: 100,
      type: "number",
    },
    {
      name: "cuttingSpeedFactor",
      unit: "%",
      min: 0,
      max: 100,
      type: "number",
    },
    {
      name: "cuttingMachinePaletChangeTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingMachineClearingMachineRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "cuttingMachineMaterialGroups",
      type: "multiselect",
      jsonOptions: machineSettings?.cuttingMachineMaterialGroupOptions,
      jsonValueKey: 'materialGroupIds'
    },
  ];

  const bendingMachineSetting: MachineInput[] = [
    {
      name: "possibleBendingWidth",
      unit: "mm",
      min: 0,
      type: "number",
    },
    {
      name: "possibleBendingHeight",
      unit: "mm",
      min: 0,
      type: "number",
    },
    {
      name: "possibleBendingWeight",
      unit: "kg",
      min: 0,
      type: "number",
    },
    {
      name: "bendingMachineSpeedFactor",
      unit: "%",
      min: 0,
      max: 100,
      type: "number",
    },
    {
      name: "bendingMachineHourRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "bendingMachineHourRateAddition",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "conditionWeightRateAddition",
      min: 0,
      type: "number",
      unit: "kg",
    },
    {
      name: "conditionSizeRateAddition",
      min: 0,
      type: "number",
      unit: "mm2",
    },
    {
      name: "bendingMachineSetupAngleMeasuringSystem",
      min: 0,
      type: "number",
      unit: 'sec'
    },
  ];

  const deburringMachineSetting: MachineInput[] = [
    {
      name: "conditionAdditionDeburringTimeMaxWeight",
      unit: "kg",
      min: 0,
      type: "number",
    },
    {
      name: "additionDeburringTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "conditionMinWidthDeburringMachine",
      unit: "mm",
      min: 0,
      type: "number",
    },
    {
      name: "conditionMaxWidthDeburringMachine",
      unit: "mm",
      min: 0,
      type: "number",
    },
    {
      name: "conditionMinWeightDeburringMachine",
      unit: "kg",
      min: 0,
      type: "number",
    },
    {
      name: "conditionMaxWeightDeburringMachine",
      unit: "kg",
      min: 0,
      type: "number",
    },
    {
      name: "minimumDeburringTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "machineDeburring",
      type: "divider",
    },
    {
      name: "activeMachineDeburring",
      type: "checkbox",
    },
    {
      name: "machineDeburringSpeed",
      unit: "m/min",
      min: 0,
      type: "number",
    },
    {
      name: "machineDeburringSetupTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "machineDeburringHourRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "manualDeburring",
      type: "divider",
    },
    {
      name: "manualDeburringSpeed",
      unit: "mm2/sec",
      min: 0,
      type: "number",
    },
    {
      name: "manualDeburringSetupTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "manualDeburringHourRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
  ];

  const threadMachineSetting: MachineInput[] = [
    {
      name: "timePerThread",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "timePerSink",
      unit: "sec",
      min: 0,
      type: "number",
    },
    {
      name: "machineMechanicalProcessesHourRate",
      unit: "EUR/h",
      min: 0,
      type: "number",
    },
    {
      name: "machineMechanicalProcessesSetupTime",
      unit: "sec",
      min: 0,
      type: "number",
    },
  ];

  return (
    <>
      {contextHolder}
      <h3>
        {t("machine.settings")} ({t("active")})
      </h3>

      <Collapse accordion expandIconPosition="end">
        <Panel
          header={t("machineSettings.cuttingMachine")}
          key={`cutting-machine`}
        >
          <div className="pt-3">
            <SheetMetalMachineForm
              formName={`cutting-machine`}
              formInputs={cuttingMachineSetting}
              formInputValues={
                machineSettings?.sheetMetalCuttingMachineSettings
              }
              apiURL={API.SHEET_METAL_CUTTING_MACHINE_SETTINGS}
            />
          </div>
        </Panel>
        <Panel
          header={t("machineSettings.bendingMachine")}
          key={`bending-machine`}
        >
          <div className="pt-3">
            <SheetMetalMachineForm
              formName={`bending-machine`}
              formInputs={bendingMachineSetting}
              formInputValues={
                machineSettings?.sheetMetalBendingMachineSettings
              }
              apiURL={API.SHEET_METAL_BENDING_MACHINE_SETTINGS}
            />
          </div>
        </Panel>
        <Panel
          header={t("machineSettings.deburringMachine")}
          key={`deburring-machine`}
        >
          <div className="pt-3">
            <SheetMetalMachineForm
              formName={`deburing-machine`}
              formInputs={deburringMachineSetting}
              formInputValues={
                machineSettings?.sheetMetalDeburringMachineSettings
              }
              apiURL={API.SHEET_METAL_DEBURRING_MACHINE_SETTINGS}
            />
          </div>
        </Panel>
        <Panel
          header={t("machineSettings.threadAndCounterSinkMachine")}
          key={`thread-counter-sink-machine`}
        >
          <div className="pt-3">
            <SheetMetalMachineForm
              formName={`thread-counter-sink-machine`}
              formInputs={threadMachineSetting}
              formInputValues={machineSettings?.sheetMetalThreadAndCounterSinkMachineSettings}
              apiURL={API.SHEET_METAL_THREAD_COUNTER_SINK_MACHINE_SETTINGS}
            />
          </div>
        </Panel>
      </Collapse>
    </>
  );
};

export default SheetMetalMachineSettings;

import fileDownload from "js-file-download";
import services from ".";
import { API } from "../constants/api.constant";
import { CuttingType } from "../models/CuttingType";
import { PartSheetMetalDxfRequest } from "../models/requests/PartSheetMetalDxfRequest";
import { PartSheetMetalStpRequest } from "../models/requests/PartSheetMetalStpRequest";
import Utils from "../utils";
import { PartSheetMetalDwgRequest } from "app/models/requests/PartSheetMetalDwgRequest";
import { Formula } from "app/models/Formula";

export const upload = async (data: {
  projectId: any;
  cuttingType: CuttingType;
  cadFile: any;
}) => {
  const formData = new FormData();
  formData.append("file", data.cadFile);
  formData.append("projectId", data.projectId);
  formData.append("cuttingType", data.cuttingType);
  try {
    const rs = await services.post(API.PART_UPLOAD, formData);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateParameters = async (data: any) => {
  try {
    // const formData = Utils.jsonToFormData(data);
    const rs = await services.post(API.PART_UPDATE_MILLING_PARAMETERS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateSheetMetalStpParameters = async (
  data: PartSheetMetalStpRequest
) => {
  try {
    // const formData = Utils.jsonToFormData(data);
    const rs = await services.post(
      API.PART_UPDATE_SHEETMETAL_STP_PARAMETERS,
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateSheetMetalDxfParameters = async (
  data: PartSheetMetalDxfRequest
) => {
  try {
    // const formData = Utils.jsonToFormData(data);
    const rs = await services.post(
      API.PART_UPDATE_SHEETMETAL_DXF_PARAMETERS,
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateSheetMetalDwgParameters = async (
  data: PartSheetMetalDwgRequest
) => {
  try {
    // const formData = Utils.jsonToFormData(data);
    const rs = await services.post(
      API.PART_UPDATE_SHEETMETAL_DWG_PARAMETERS,
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const uploadTechDrawingFile = async (data: { id: any; file: any }) => {
  try {
    const formData = Utils.jsonToFormData(data);
    const rs = await services.post(API.PART_UPLOAD_TECHDRAWING_FILE, formData);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const requestTechDrawingData = async (file: any) => {
  try {
    const formData = Utils.jsonToFormData({ file });
    const rs = await services.post(
      API.PART_REQUEST_TECH_DRAWING_DATA,
      formData
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const calculate = async (id: number) => {
  try {
    const rs = await services.post(
      API.PART_CALCULATE.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getReferenceCosts = async (id: any) => {
  try {
    const rs = await services.get(
      API.PART_REFERENCE_COSTS.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateQuantity = async (data: { id: any; quantity: number }) => {
  try {
    const rs = await services.post(
      API.PART_UPDATE_QUANTITY.replace(":id", data.id),
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const deletePart = async (id: any) => {
  try {
    const rs = await services.delete(API.PART.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getMiniReport = async (id: any) => {
  try {
    const rs = await services.get(API.PART_MINI_REPORT.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getProcessingReport = async (id: any) => {
  try {
    const rs = await services.get(
      API.PART_PROCESSING_REPORT.replace(":id", id)
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const get3dViewer = async ({ width, height, margin }: any) => {
  try {
    const rs = await services.get(API.PART_3D_VIEWER, {
      params: { width, height, margin },
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const get3dModel = async (partId?: any) => {
  try {
    const rs = await services.get(API.PART_3D_MODEL.replace(":id", partId));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const copy = async (data: { partIds: any[]; projectId?: number }) => {
  try {
    const rs = await services.post(API.PART_COPY, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};
const move = async (data: { partIds: any[]; projectId?: number }) => {
  try {
    const rs = await services.post(API.PART_MOVE, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const toggleApprove = async (data: { partId: any; approved?: boolean }) => {
  try {
    const rs = await services.post(API.PART_TOGGLE_APPROVE, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const toggleApproveByAccessCode = async (data: {
  partId: any;
  approved?: boolean;
  accessCode: string;
}) => {
  try {
    const rs = await services.post(
      API.PART_TOGGLE_APPROVE_BY_ACCESS_CODE.replace(
        ":accessCode",
        data.accessCode
      ),
      data
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getCostReport = async (id: any) => {
  try {
    const rs = await services.get(API.PART_COST_REPORT.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const postPreviewAdaptedPart = async (id: any, formData: any) => {
  try {
    const url = API.PART_PREVIEW_ADAPTED.replace(":id", id);
    const rs = await services.post(url, formData);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const postUpdatePartTimeAndCostsAdapt = async (id: any, formData: any) => {
  try {
    const url = API.PART_UPDATE_TIME_AND_COSTS_ADAPT.replace(":id", id);
    const rs = await services.post(url, formData);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getEmissionReport = async (id: any) => {
  try {
    const rs = await services.get(API.PART_EMISSION_REPORT.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const postCustomSheetMetalConfig = async (
  formData: any,
  projectId: any,
  shapeType: any
) => {
  try {
    const url = API.PART_DXF_CUSTOM_POST.replace(
      ":projectId",
      projectId
    ).replace(":shapeType", shapeType);
    const rs = await services.post(url, formData);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const getDxfContent = async (id: any) => {
  try {
    const rs = await services.get(
      `${API.PART_DOWNLOAD_ALL_LAYERS_DXF.replace(":id", id)}`,
      {
        responseType: "text",
      }
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const downloadCadFile = async ({ id, name }: { id: any; name?: string }) => {
  try {
    const rs = await services.get(
      API.PART_DOWNLOAD_CAD_FILE.replace(":id", id),
      {
        responseType: "blob",
      }
    );
    fileDownload(rs.data, `${name}`);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const updateName = async (id: any, name: string) => {
  try {
    const rs = await services.patch(API.PART_UPDATE_NAME.replace(":id", id), {
      name: name,
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const previewPrices = async (data: {
  partIds: any[];
  formulaData: Formula;
}) => {
  try {
    const rs = await services.post(API.PART_PREVIEW_PRICES, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const previewSheetMetalPrices = async (data: {
  partIds: any[];
  formulaData: Formula;
}) => {
  try {
    const rs = await services.post(API.PART_SHEET_METAL_PREVIEW_PRICES, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const checkDesignPrinciple = async (id: any) => {
  try {
    const rs = await services.get(API.PART_CHECK_DESIGN_PRINCIPLE.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};
const partServices = {
  upload,
  updateParameters,
  updateSheetMetalStpParameters,
  updateSheetMetalDxfParameters,
  updateSheetMetalDwgParameters,
  uploadTechDrawingFile,
  requestTechDrawingData,
  calculate,
  getReferenceCosts,
  updateQuantity,
  deletePart,
  getMiniReport,
  getProcessingReport,
  get3dViewer,
  get3dModel,
  copy,
  move,
  toggleApprove,
  toggleApproveByAccessCode,
  getCostReport,
  getEmissionReport,
  postCustomSheetMetalConfig,
  downloadCadFile,
  getDxfContent,
  updateName,
  previewPrices,
  postRecalcutatePart: postPreviewAdaptedPart,
  postUpdatePartTimeAndCostsAdapt,
  checkDesignPrinciple,
  previewSheetMetalPrices
};

export default partServices;
